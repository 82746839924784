import React from 'react';
import _ from 'lodash';
import { Collapsible, CollapsibleItem } from 'react-materialize';
import { features } from '../services/misc';

class About extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			features: <div>Unable to load feature list</div>
		};
	}

	componentDidMount() {
		features().then((response) => {
			this.setState({
				features: _.map(response.data.features, (feature) => <CollapsibleItem header={feature.header}>
					{feature.body}
				</CollapsibleItem>)
			})
		})
	}

	render() {
		return (
			<div>
				<div className='row'>
					<div className='col s12'>
						<h1>About Beymax</h1>
					</div>
				</div>

				<div className='row'>
					<div className='col s12'>
						<p className='flow-text'>
							Beymax is a Discord Bot and advanced API for making Discord bots in Python
						</p>
					</div>
				</div>

				<div className='row'>
					<div className='col s12'>
						<h2>Bot Features</h2>
					</div>
				</div>

				<div className='row'>
					<div className='col s12'>
						<p className='flow-text'>
							Features may vary between Beymax instances. The following list are the features available in the instance
							hosting this website
						</p>
					</div>
				</div>

				<div className='row'>
					<div className='col s12'>
						<Collapsible accordion={true}>
							{this.state.features}
						</Collapsible>
					</div>
				</div>

				<div className='row'>
					<div className='col s12'>
						<h2>API Features</h2>
					</div>
				</div>

				<div className='row'>
					<div className='col s12'>
						<Collapsible accordion={true}>
							<CollapsibleItem header="Configurable Controller Options">
								Beymax's API supports different controllers for different deployment types.
								A Dummy controller is available for extremely simple deployment of a single-sharded bot.
								A Web controller is available for multi-sharded, multi-server bots. That's the controller
								you're looking at right now!
							</CollapsibleItem>
							<CollapsibleItem header="Advanced Event Framework">
								Beymax's API is entirely event-driven, with all bot features (such as commands, backround tasks, etc)
								all run as events, which you can add arbitrary event listeners for. All events have a before, during, and after
								phase, allowing event listeners to add arbitrary code or control logic to events. For instance, an event listener
								could subscribe to <code>before:guild_join</code> and, if it determines that the bot shouldn't join the guild,
								it could immediately leave the guild then cancel the event to prevent the main event listeners from running for that event.
							</CollapsibleItem>
							<CollapsibleItem header="Native database integration">
								Beymax's API is tightly integrated with the python library <code>SQLAlchemy</code> which supports any
								SQL-like database. The API stores all kinds of crucial data in the database, and exposes an interface for
								defining database schema as well as database migrations in code as part of command suites. If your bot is simple and you don't need to
								store anything complicated, the API provides a simple method for storing and retreiving key-value pairs
								from the database. For the simplest of bots, an SQLite database file is extremely easy to set up and perfetly
								sufficient for running the bot.
							</CollapsibleItem>
							<CollapsibleItem header="Schedule future events">
								Beymax's API allows internal events from being set for the future. The API usually dispatches the event within 1s
								of the desired time, and guarantees a worst-case time of at most 30s. This allows certain cleanup tasks to be scheduled
								at specific times in the future instead of having background tasks periodically run a global cleanup
							</CollapsibleItem>
							<CollapsibleItem header="Special Message Handlers">
								Beymax's API adds convenience layers over <code>discord.py</code> to allow a handler coroutine to be invoked any time a
								message is recieved which meets user-specified conditions
							</CollapsibleItem>
							<CollapsibleItem header="Functional UI Library">
								Beymax's API wraps the native <code>discord.py</code> UI library and provides a functional-programming style interface.
								This allows discord Views and Modals to be defined dynamically in code using function decorators to add functionality to UI elements
							</CollapsibleItem>
						</Collapsible>
					</div>
				</div>
			</div>
		);
	}
};

export default About;