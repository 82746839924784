import axios from 'axios';

function clientid() {
	return axios.get('/api/client-id');
}

function schema() {
	return axios.get('/api/config/schema');
}

function features() {
	return axios.get('/api/features');
}

function guild_count() {
	return axios.get('/api/guild-count');
}

function channel_types() {
	return axios.get('/api/channel-types');
}

function csrf() {
	return axios.post('/api/csrf', {}, { withCredentials: true })
}

export { clientid, schema, channel_types, csrf, features, guild_count };