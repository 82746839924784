import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';

// Import pages here
import Home from './pages/Home';
import About from './pages/About';
import Guild from './pages/Guild';
import FiveOhThree from './pages/503';
import Setup from './pages/Setup';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <main>
        <div className="root-container container">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/server" element={<Guild />} />
            <Route exact path="/503" element={<FiveOhThree />} />
            <Route exact path="/setup" element={<Setup />} />
          </Routes>
        </div>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
