import React from 'react';
import './Footer.css';
import { guild_count } from '../services/misc';

class Footer extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			count: 0
		};
	}

	componentDidMount() {
		guild_count().then((response) => {
			this.setState({
				count: response.data.guilds
			})
		})
	}

	render() {
		return <footer className='page-footer blue lighten-2'>
			<div className='container black-text'>
				<div className='row'>
					<div className='col s4'>
						Powered by <a href='https://gitlab.graubert.com/agraubert/Beymax'>Beymax</a>
					</div>
					<div className='col s4'>
						Serving {this.state.count} servers, and counting
					</div>
					<div className='col s4'>
						<a href="/eula">End-User License Agreement</a>
					</div>
				</div>
			</div>
		</footer>;
	}

};

export default Footer;