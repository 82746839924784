import React from 'react';
import Cookies from 'js-cookie';


class FiveOhThree extends React.Component {

	constructor(props) {
		super(props);

		// See if this works without a domain
		Cookies.remove("auth", { path: "/" });
	}

	render() {
		return (
			<div>
				<div className='row'>
					<div className='col s12'>
						<h1>Service Unavailable</h1>
					</div>
				</div>

				<div className='row'>
					<div className='col s12'>
						<p className='flow-text'>
							The web configuration interface is currently not available. We rely on at least one
							bot shard to inform the web interface of certain metadata. You shouldn't have been
							able to reach the server configuration page without a bot shard being online, but
							you did somehow (good job!). Please close this page, wait a few minutes, then
							run the <code>/config</code> command in Discord again.
						</p>
					</div>
				</div>

			</div>
		);
	}
};

export default FiveOhThree;