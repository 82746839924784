import React from 'react';
import { is_logged_in } from '../utils';
import M from 'materialize-css';
import { Navigate } from 'react-router-dom';

class LoginGate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			login: is_logged_in()
		};
	}

	componentDidMount() {
		if (!this.state.login) M.toast({ html: "To view this page, you must login with the Discord /config command" })
	}

	render() {
		return (
			<div>
				{this.state.login ? <span></span> : <Navigate to='/' />}
			</div>
		)
	}
}

export default LoginGate;