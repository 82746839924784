import React from 'react';
import { NavLink } from "react-router-dom";
import _ from 'lodash';
import M from 'materialize-css';

import './Header.css';

class Header extends React.Component {

	constructor(props) {
		super(props);
		// Create a react reference for the sidenav (mobile)
		this.sidenav = React.createRef();
		this.sidenav_handle = null;

		this.closeSidenav = () => {
			if (this.sidenav_handle) this.sidenav_handle.close();
		}
	}

	componentDidMount() {
		// When component renders, initialize the sidenav and get a materialize handle to it
		this.sidenav_handle = M.Sidenav.init(this.sidenav.current);
	}

	render() {

		let navbar_elements = [
			<NavLink to='/' className={({ isActive }) => isActive ? 'active' : ''}>Home</NavLink>,
			<NavLink to='/about' className={({ isActive }) => isActive ? 'active' : ''}>About</NavLink>,
			<NavLink to='/setup' className={({ isActive }) => isActive ? 'active' : ''}>Setup</NavLink>,
			<NavLink to='/server' className={({ isActive }) => isActive ? 'active' : ''}>Configure Server</NavLink>
		];

		return (
			< div >
				<div className='navbar-fixed'>
					<nav className='blue lighten-2'>
						<a href="#" data-target='navbar-slide-out' className='sidenav-trigger'>
							<i className='material-icons black-text'>menu</i>
						</a>
						<div className='nav-wrapper hide-on-med-and-down'>
							<ul className='left'>
								{_.map(navbar_elements, (el, idx) => <li key={el.props.to} >{el}</li>)}
							</ul>
						</div>
					</nav>
				</div>
				<ul id='navbar-slide-out' className='sidenav' ref={this.sidenav}>
					{_.map(navbar_elements, (el, idx) => <li key={el.props.to}>{el}</li>)}
				</ul>
			</div >
		);
	}

};

export default Header;