import React from 'react';
import _ from 'lodash';
import { clientid } from '../services/misc';
import M from 'materialize-css';
import './Home.css';

class Home extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			client_id: null,
			icon_url: null
		};
	}

	componentDidMount() {
		clientid().then(
			(response) => {
				this.setState({
					client_id: response.data['client-id'],
					icon_url: response.data['icon_url']
				});
			}
		).catch(
			(error) => {
				if (error.response.status === 503) {
					M.toast({ html: "Sorry, the service is not fully available yet. Please try again in a few minutes" })
				}
				console.error(error);
			}
		)
	}

	render() {
		return (
			<div>
				<div className='row'></div>

				<div className='row'>
					<div className='col s4 offset-s2 icon-align'>
						{/* Beymax's application icon */}
						<img className='beymax-icon' src={this.state.icon_url} alt='Beymax icon' />
					</div>
					<div className='s6 valign-wrapper'>
						<h1>Beymax</h1>
					</div>
				</div>

				<div className='row'>
					<div className='col s12 center-align'>
						<p className='flow-text'>A customizable Discord Bot and Command-focused bot-building API</p>
					</div>
				</div>

				<div className='hide-on-small-only'>
					<div className='row center'>
						<div className='col s3 offset-s2' >
							<a className={'btn waves-effect waves-light blue lighten-2 black-text' + (_.isNull(this.state.client_id) ? ' disabled' : '')} href={`https://discord.com/api/oauth2/authorize?client_id=${this.state.client_id}&permissions=872827984&scope=bot`}>Invite to your Server</a>
						</div>
						<div className='col s3'>
							<a className='btn waves-effect waves-light blue lighten-2 black-text' href='https://gitlab.graubert.com/agraubert/Beymax/-/wikis/home'>Documentation</a>
						</div>
						<div className='col s3'>
							<a className='btn waves-effect waves-light blue lighten-2 black-text' href='https://gitlab.graubert.com/agraubert/Beymax'>GitLab</a>
						</div>
					</div>
				</div>

				<div className='hide-on-med-and-up'>
					<div className='row'>
						<div className='col s4 offset-s5'>
							<a className={'btn waves-effect waves-light blue lighten-2 black-text' + (_.isNull(this.state.client_id) ? ' disabled' : '')} href={`https://discord.com/api/oauth2/authorize?client_id=${this.state.client_id}&permissions=872827984&scope=bot`}>Invite to your Server</a>
						</div>
					</div>
					<div className='row'>
						<div className='col s4 offset-s5'>
							<a className='btn waves-effect waves-light blue lighten-2 black-text' href='https://gitlab.graubert.com/agraubert/Beymax/-/wikis/home'>Documentation</a>
						</div>
					</div>
					<div className='row'>
						<div className='col s4 offset-s5'>
							<a className='btn waves-effect waves-light blue lighten-2 black-text' href='https://gitlab.graubert.com/agraubert/Beymax'>GitLab</a>
						</div>
					</div>
				</div>


			</div>
		);
	}
};

export default Home;
