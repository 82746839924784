import axios from 'axios';
import _ from 'lodash';

function guild() {
	return axios.get('/api/guild', { withCredentials: true });
}

function update_guild_conf(csrf_token, config, control) {
	let request_data = {};
	if (_.isObject(config)) request_data['guild_config'] = _.cloneDeep(config);
	if (_.isObject(control)) request_data['guild_config_ctrl'] = _.cloneDeep(control);
	if (_.isEmpty(request_data)) throw Error("No data was provided to this update");
	return axios.post(
		'/api/guild/config',
		request_data,
		{
			withCredentials: true,
			headers: {
				'X-Beymax-Csrf': csrf_token
			}
		},

	)
}


function guild_channels(channel_type) {
	if (_.isArray(channel_type)) channel_type = _.join(channel_type, ',');
	return axios.get('/api/guild/channels/' + channel_type, { withCredentials: true });
}

export { guild, update_guild_conf, guild_channels };