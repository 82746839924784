import React from 'react';
import { Collapsible, CollapsibleItem } from 'react-materialize';


class Setup extends React.Component {

	render() {
		return <div>
			<div className='row'>
				<div className='col s12'>
					<h1>Admin Setup Guide</h1>
				</div>
			</div>

			<div className='row'>
				<div className='col s12'>
					<Collapsible accordion={true}>
						<CollapsibleItem header="Invite to your server">
							You can invite this Bot to your Discord servers and start using it immediately by clicking the Invite link on the
							<a href='/'>homepage</a>
						</CollapsibleItem>
						<CollapsibleItem header="Initial Configuration">
							When the Bot is first invited to your server, you'll notice that all of the commands will reply with "This command has been disabled by your server's administrators".
							This is a safeguard to prevent users from running wild with powerful commands before you've had a chance to review them. Any user with Administrator permissions in your
							server will be able to use the <code>/config</code> command, to open up a configuration page on this website. Through that page, you'll be able to enable/disable specific commands
							or context menu entries, as well as change other parts of the Bot configuration
						</CollapsibleItem>
						<CollapsibleItem header="Suite Configuration">
							You'll want to review the Command Suites identify any commands or context menus you want in your server. Before enabling them,
							you should edit your server permissions in Discord to ensure that only the users and roles you want can invoke new commands.
							You can find these settings in Server Settings &gt; Integrations &gt; (this bot). You may want to restrict commands such as
							<code>/echo</code> or <code>/viewdb</code> to moderators and above. After setting command permissions, it is safe to enable
							them through the configuration page on this website. Changes to Suites, Commands, Context Menus, and Channel References take
							up to 30 seconds to propagate to the Bot.
						</CollapsibleItem>
						<CollapsibleItem header="Misc Configuration">
							Under the Channel References section, you'll find a general Configuration setting. This is where you can configure the behavior
							of Bot features which allow for configuration. For instance, the Party server can be configured to change where party channels
							are created. Changes to general Configuration take effect immediately
						</CollapsibleItem>
					</Collapsible>
				</div>
			</div>


		</div>;
	}

}

export default Setup;