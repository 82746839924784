import React from 'react';
import _ from 'lodash';
import M from 'materialize-css';
import { Collapsible, CollapsibleItem, Select, Switch } from 'react-materialize';
import { guild, update_guild_conf, guild_channels } from '../services/guild';
import { schema, csrf } from '../services/misc';
import LoginGate from '../components/LoginGate';
import { Navigate } from 'react-router-dom';
import './Guild.css';

function generate_config_elem(field, channel_types, current, onChange) {
	let inner_elem = null;
	if (field.primative_type === "boolean") inner_elem = <Switch offLabel="Off" onLabel="On" onChange={onChange} checked={current} data-value-target="checked" />
	else if (field.primative_type === "channel") {
		inner_elem = generate_channel_select(field.type, channel_types, current, onChange);
	}

	return <div>
		<div className='hide-on-med-and-down'>
			<div className="row">
				<div className='col s2'>
					{field.keypath}
				</div>
				<div className='col s8'>
					<p className='flow-text'>
						<code>{field.description}</code>
					</p>
				</div>
				<div className='col s2'>
					{inner_elem}
				</div>
			</div>
		</div>
		<div className='hide-on-large-only'>
			<div className='row'>
				<div className='col l8 offset-l2'>
					{field.keypath}
				</div>
			</div>
			<div className='row'>
				<div className='col l8 offset-l2'>
					<p className='flow-text'>
						<code>{field.description}</code>
					</p>
				</div>
			</div>
			<div className='row'>
				<div className='col l8 offset-l2'>
					{inner_elem}
				</div>
			</div>
			<hr />
		</div>
	</div>
}

function generate_channel_select(channel_type, channel_types, current, onChange) {
	let options = _.map(
		_.get(channel_types, channel_type) || [],
		(channel) => <option value={channel.id}>
			{channel.name}
		</option>
	)
	if (_.isUndefined(current)) {
		options = _.concat(
			[
				<option disabled value="_default_">
					Choose a channel
				</option>
			],
			options
		)
	}
	return <Select onChange={onChange} value={_.isUndefined(current) ? "_default_" : _.toString(current)} data-value-target="value">
		{options}
	</Select>;
}

class Guild extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			guild: {},
			schema: {},
			control_elem: <div></div>,
			config_elem: <div></div>,
			channels: {},
			csrf: null
		};

		this.collapsible = React.createRef();

		this.applyControlState = function (_ths, event) {
			let forminput = event.target;
			const section = forminput.getAttribute("data-section");
			const suite_name = forminput.getAttribute("data-suite");
			let guild_data = _.cloneDeep(_ths.state.guild);
			if (section === "suites") guild_data.control.suites[suite_name].enabled = guild_data.control.suites[suite_name].forced || forminput.checked;
			else if (section === "commands") guild_data.control.suites[suite_name].commands[forminput.getAttribute("data-command")].enabled = forminput.checked;
			else if (section === "contexts") guild_data.control.suites[suite_name].contexts[forminput.getAttribute("data-context")].enabled = forminput.checked;

			_ths.setState({
				guild: guild_data
			});
		}

		this.applyConfigState = function (_ths, field, event) {
			let forminput = event.target;
			let guild_data = _.cloneDeep(_ths.state.guild);
			let raw_value = _.get(forminput, forminput.getAttribute("data-value-target"));
			_.set(guild_data.config, field.keypath, raw_value);
			_ths.setState({
				guild: guild_data
			})
		}

		this.applyChannelState = function (_ths, channel, event) {
			let forminput = event.target;
			let guild_data = _.cloneDeep(_ths.state.guild);
			let raw_value = _.get(forminput, forminput.getAttribute("data-value-target"));
			_.set(guild_data.control.channels, channel, raw_value);
			_ths.setState({
				guild: guild_data
			});
			console.log(guild_data);
		}

	}

	componentDidMount() {
		csrf().then((response) => {
			this.setState({
				csrf: response.data.token
			})
		});

		guild().then((response) => {

			let control_elem = _.map(response.data.control.suites, (suite, name) => <CollapsibleItem expanded={false} header={
				suite.display_name + (
					_.has(response.data, ['dependencies', name]) ? ": Required by " + _.join(response.data.dependencies[name], ',') + " -- Enabled implicitly if any dependant suites are enabled" : ""
				)
			}>
				<div className='row'>
					<div className='col s3'>
						Enable Suite
					</div>
					<div className='switch col s3 right'>
						<label>
							Disabled
							<input type="checkbox" name={"suite:" + name} onChange={_.bind(this.applyControlState, this, this)} data-section="suites" data-suite={name} data-display-name={suite.display_name} defaultChecked={suite.forced || suite.enabled} data-forced={suite.forced} disabled={suite.forced} />
							<span class="lever"></span>
							Enabled
						</label>
					</div>
				</div>
				Commands
				<div className='container'>
					<table>
						<tbody>
							{_.map(suite.commands, (cmd, cmdname) => <tr>
								<td>{cmd.display_name}</td>
								<td>
									<div className='switch'>
										<label>
											Disabled
											<input type='checkbox' name={"cmd:" + name} onChange={_.bind(this.applyControlState, this, this)} data-section="commands" data-suite={name} data-display-name={cmd.display_name} data-command={cmdname} defaultChecked={cmd.enabled} />
											<span class="lever"></span>
											Enabled
										</label>
									</div>
								</td>
							</tr>)}
						</tbody>
					</table>

				</div>
				<p></p>
				Context Menu Entries
				<div className='container'>
					<table>
						<tbody>
							{_.map(suite.contexts, (ctx, ctxname) => <tr>
								<td>{ctx.display_name}</td>
								<td>
									<div className='switch'>
										<label>
											Disabled
											<input type='checkbox' name={"ctx:" + name} onChange={_.bind(this.applyControlState, this, this)} data-section="contexts" data-suite={name} data-display-name={ctx.display_name} data-context={ctxname} defaultChecked={ctx.enabled} />
											<span class="lever"></span>
											Enabled
										</label>
									</div>
								</td>
							</tr>)}
						</tbody>
					</table>

				</div>

			</CollapsibleItem>);

			this.setState({
				guild: response.data,
				control_elem: control_elem
			});

		}).catch(
			(error) => {
				if (error.response.status === 503) {
					M.toast({ html: "Sorry, the service is not yet available. Please try again in a few minutes" });
					this.setState({
						control_elem: <Navigate to="/503" />
					});
				} 
				console.error(error);
			}
		);

		schema().then((response) => {
			this.setState({
				schema: response.data
			});


			if (response.data.channel_types.length) {
				guild_channels(response.data.channel_types).then((channel_data) => {
					this.setState({
						channel_elem: <table>
							<thead>
								<tr>
									<th>Reference Name</th>
									<th>Selected Channel</th>
								</tr>
							</thead>
							<tbody>
								{_.map(response.data.channel_references, (channel) => <tr>
									<td>
										{channel}
									</td>
									<td>
										{generate_channel_select('text', channel_data.data.channels, _.get(this.state.guild.control.channels, channel), _.bind(
											this.applyChannelState, this, this, channel
										))}
									</td>
								</tr>)}
							</tbody>
						</table>,
						config_elem: <form>
							{
								_.map(_.get(response.data, 'headings'), (heading, idx) =>
									<div>
										<div className='row'>
											<h4>{heading}</h4>
										</div>

										{
											_.map(
												_.filter(
													response.data.schema,
													['heading', heading]
												),
												(confitem) => <div className='row'>
													<div className='col s12'>
														{generate_config_elem(confitem, channel_data.data.channels, _.get(this.state.guild.config, confitem.keypath), _.bind(
															this.applyConfigState, this, this, confitem
														))}
													</div>
												</div>
											)
										}
									</div>

								)
							}
						</form>,
						channels: channel_data.data.channels
					});
				}).catch(
					(error) => {
						if (error.response.status === 503) {
							M.toast({ html: "Sorry, the service is not yet available. Please try again in a few minutes" });
							this.setState({
								control_elem: <Navigate to="/503" />
							})
						}
						console.error(error);
					}
				);
			} else {
				this.setState({
					config_elem: <form>
						{
							_.map(_.get(response.data, 'headings'), (heading, idx) =>
								<div>
									<div className='row'>
										<h4>{heading}</h4>
									</div>

									{
										_.map(
											_.filter(
												response.data.schema,
												['heading', heading]
											),
											(confitem) => <div className='row'>
												<div className='col s12'>
													{confitem.keypath}
												</div>
											</div>
										)
									}
								</div>

							)
						}
					</form>
				});
			}

		}).catch(
			(error) => {
				if (error.response.status === 503) {
					M.toast({ html: "Sorry, the service is not yet available. Please try again in a few minutes" });
					this.setState({
						control_elem: <Navigate to="/503" />
					})
				}
				console.error(error);
			}
		);
	}

	handleControlSubmit(event) {
		event.preventDefault();
		const _ths = this;
		update_guild_conf(this.state.csrf, null, this.state.guild.control).then(
			(response) => {
				console.log("Updated guild control");
				_ths.setState({ guild: response.data, csrf: response.headers['x-beymax-csrf'] }); // Response will contain guild conf update
				M.toast({ html: "Updates to suites, commands, context menus, and channels will take effect in 30 seconds" });
			}
		).catch(
			(error) => {
				if (error.response.status === 401) {
					M.toast({ html: "Server rejected authentication or CSRF protections. Please refresh the page and try again" });
				}
				else M.toast({ html: "Unable to update the Server Suite configuration. See your console for details" });
				console.error(error);
			}
		)
	}

	handleConfigSubmit(event) {
		event.preventDefault();
		const _ths = this;
		update_guild_conf(this.state.csrf, this.state.guild.config, null).then(
			(response) => {
				console.log("Updated guild config");
				_ths.setState({ guild: response.data, csrf: response.headers['x-beymax-csrf'] }); // Response will contain guild conf update
				M.toast({ html: "Configuration Updated!" });
			}
		).catch(
			(error) => {
				if (error.response.status === 401) {
					M.toast({ html: "Server rejected authentication or CSRF protections. Please refresh the page and try again" });
				}
				else M.toast({ html: "Unable to update the Server Configuration. See your console for details" });
				console.error(error);
			}
		)
	}

	render() {
		return (
			<div>
				<LoginGate />
				<div className='row'></div>
				<div className='row hide-on-large-only'>
					<div className='col s12'>
						<p className='flow-text'>
							I'm not a great web designer, so I appologize for this awful mobile layout
						</p>
					</div>
				</div>

				<div className='row'>
					<div className='col s4 icon-align'>
						<img className='server-icon' src={this.state.guild.icon_url} alt='Server icon' />
					</div>
					<div className='col s8'>
						<h1>{this.state.guild.name}</h1>
						<code>Server ID: {this.state.guild.id}</code>
						<p>Served by shard {this.state.guild.current_shard}</p>
					</div>
				</div>

				<div className='row'>
					<div className='col s12'>
						<h3>Command Suites</h3>
					</div>
				</div>

				<div className='row'>
					<div className='col s12'>
						<form>
							<Collapsible accordion>
								{this.state.control_elem}
							</Collapsible>

						</form>

						<div className='row'>
							<div className='col s12'>
								<h4>Channel References</h4>
							</div>
						</div>

						<div className='row'>
							<div className='col s12'>
								Certain bot features may send messages to specific Text Channels rather than responding to a user command.
								You can specify the channel references and what actual channel they refer to here.
							</div>
						</div>

						<form>
							{this.state.channel_elem}
						</form>

						<div className='row'></div>

						<div className='row'>
							<div className='col s10 offset-s2 l4 offset-l10'>
								<button className="btn waves-effect waves-light blue lighten-2 black-text" onClick={_.bind(this.handleControlSubmit, this)}>Update Features</button>
							</div>
						</div>

					</div>
				</div>

				<hr />

				<div className='row'>
					<div className='col s12'>
						<h3>Configuration</h3>
					</div>
				</div>

				<div className='row'>
					<div className='col s12'>
						{this.state.config_elem}
					</div>
				</div>

				<div className='row'>
					<div className='col s10 offset-s2 l4 offset-l10'>
						<button className='btn waves-effect waves-light blue lighten-2 black-text' onClick={_.bind(this.handleConfigSubmit, this)}>Update Configuration</button>
					</div>
				</div>


			</div>
		);
	}
};

export default Guild;